import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop(): JSX.Element {
  const { pathname } = useLocation();

  useEffect(() => {
    const root = document.getElementById('root');
    root?.scrollTo({ top: 0 });
  }, [pathname]);

  return <div />;
}
