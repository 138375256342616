import type { LoginData, MarketplaceSuccessRes, Network } from '@shared';
import type { AxiosError, AxiosResponse } from 'axios';

import { API_ERROR_MSG, USER_DATA } from '@common';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import i18n from 'i18next';
import { toast } from 'react-toastify';

const { t } = i18n;

const { REACT_APP_API_BASE_URL } = process.env;
const client = axios.create({
  baseURL: `${REACT_APP_API_BASE_URL}/api/v1`,
});

client.interceptors.request.use((config) => {
  let userData: any;
  if (!userData) {
    try {
      userData = JSON.parse(localStorage.getItem(USER_DATA) || '{}');
    } catch (error) {
      userData = {};
      Sentry.captureException(error);
    }
  }

  const { headers } = config;
  headers.Lang = i18n.language;
  if (headers && userData.token)
    headers.Authorization = `Bearer ${userData.token}`;
  return config;
});

client.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const errorCode = error.response?.status;
    let errorMsg = '';
    if (errorCode === 401) {
      errorMsg = t('error.401');
    } else if (errorCode === 404) {
      errorMsg = t('error.404');
    } else if (errorCode === 422) {
      errorMsg = t('error.422');
    } else if (errorCode === 500) {
      errorMsg = t('error.500');
    } else if (errorCode === 504) {
      errorMsg = t('error.504');
    } else if ((error.response?.data as any)?.message || error.message) {
      errorMsg = (error.response?.data as any)?.message || error.message;
    }
    if (!toast.isActive(API_ERROR_MSG) && errorMsg) {
      // only show one popup at the same time
      toast.error(errorMsg, { toastId: API_ERROR_MSG });
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  }
);

export enum APIName {
  GET_LIST_NETWORK = 'GET_LIST_NETWORK',
  LOGOUT = 'LOGOUT',
  POST_CONNECT_WALLET = 'POST_CONNECT_WALLET',
  // TODO: refactor web3.ts
  POST_GET_NONCE = 'POST_GET_NONCE',
}

export enum NonceType {
  BIND = 1,
  LOGIN = 0,
  UNBIND = 2,
}

const Action = {
  [APIName.GET_LIST_NETWORK]: async (is_active = Number(true)) => {
    const { data } = await client.get<MarketplaceSuccessRes<Network[]>>(
      '/network',
      {
        params: { is_active },
      }
    );
    return data.data;
  },
  [APIName.LOGOUT]: async () => {
    try {
      client.post('/auth/logout');
    } catch (error) {
      Sentry.captureException(error);
    }
  },
  [APIName.POST_CONNECT_WALLET]: async (
    address: string,
    signature: string,
    nonce: string,
    turnstileResponse: string,
    language: string
  ) => {
    const { data } = await client.post<MarketplaceSuccessRes<LoginData>>(
      '/auth/connect_wallet',
      {
        address,
        cf_turnstile_response: turnstileResponse,
        language,
        nonce,
        signature,
      }
    );
    return data.data;
  },
  [APIName.POST_GET_NONCE]: async (
    address: string,
    nonce: string,
    type?: number
  ) => {
    const { data } = await client.post<MarketplaceSuccessRes<string>>(
      '/auth/get_nonce',
      {
        address,
        nonce,
        type,
      }
    );
    return data.data;
  },
};

export const Fun: { [key in APIName]: (typeof Action)[key] } = Action;
