import { useState } from 'react';
import { createContainer } from 'unstated-next';

function useCache() {
  const [cacheImgList, setCacheImgList] = useState<Record<string, boolean>>({});
  const [turnstileCount, setTurnstileCount] = useState<number>(0);

  /**
   * This function is to prevent re-render skeleton if image already cached by broswer
   */
  const addCacheImg = (key: string, status = true) => {
    if (cacheImgList[key] == null)
      setCacheImgList((prev) => ({ ...prev, [key]: status }));
  };

  /**
   * Since the turnstile token can only verify once, so if there has some validation issue
   * and return status code >= 400, we have to menually refresh the token
   * use Date.now() is because in the handleGlobalResponseError Axios scope we cannot get
   * the latest value of turnstileCount (always 0)
   */
  const updateTurnstileKey = (value?: number) => {
    if (process.env.REACT_APP_TURNSTILE_SITE_KEY)
      setTurnstileCount(value != null ? value : Date.now());
  };

  return {
    addCacheImg,
    cacheImgList,
    turnstileCount,
    updateTurnstileKey,
  };
}

export const cache = createContainer(useCache);
