import { ZeroAddress } from 'ethers';

export const OTP_COUNTDOWN_SECONDS = 60;

export const USER_DATA = 'USER_DATA';
export const CHAIN_ID = 'CHAIN_ID';
export const STORAGE_HASH = 'STORAGE_HASH';
export const EMAIL_OTP_COUNT_DOWN_AT = 'EMAIL_OTP_COUNT_DOWN_AT';
export const PHONE_OTP_COUNT_DOWN_AT = 'PHONE_OTP_COUNT_DOWN_AT';
export const THEME = 'THEME';
export const LANGUAGE = 'LANGUAGE';
export const API_ERROR_MSG = 'API_ERROR_MSG';
export const LEGAL_TENDER = 'LEGAL_TENDER';
export const TREASURY_ACTION = 'TREASURY_ACTION';
export const INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS';
export const ACTION_REJECTED = 'ACTION_REJECTED';
export const SHOW_ASSET_PARAM = 'show-asset';
export const MAX_FILE_SIZE = 1024000 * 5; // 5MB
export const MIN_PASSWORD_LENGTH = 8;

export const IMAGE_PLACEHOLDER = 'IMAGE_PLACEHOLDER';
export const URL_PLACEHOLDER = '#';
export const TEXT_PLACEHOLDER = 'TEXT_PLACEHOLDER';
export const NUMBER_PLACEHOLDER = 0;
export const ADDRESS_PLACEHOLDER = ZeroAddress;

export const COUNTRY_LIST = [
  {
    cca2: 'HK',
    countryCode: '852',
    emoji: '🇭🇰',
    name: 'Hong Kong',
    timeZones: ['Asia/Hong_Kong'],
  },
  {
    cca2: 'MO',
    countryCode: '853',
    emoji: '🇲🇴',
    name: 'Macau',
    timeZones: ['Asia/Macau'],
  },
  {
    cca2: 'CN',
    countryCode: '86',
    emoji: '🇨🇳',
    name: 'China',
    timeZones: ['Asia/Shanghai', 'Asia/Urumqi'],
  },
];
