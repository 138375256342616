import type { ReactElement } from 'react';

import { api, cache, market, user, web3 } from './global';

/**
 * State providing wrapper
 * @param {ReactElement | ReactElement[]} children to inject
 * @returns {ReactElement} wrapper
 */
export default function StateProvider({
  children,
}: {
  children: ReactElement | ReactElement[];
}): ReactElement {
  return (
    <cache.Provider>
      <user.Provider>
        <web3.Provider>
          <api.Provider>
            <market.Provider>{children}</market.Provider>
          </api.Provider>
        </web3.Provider>
      </user.Provider>
    </cache.Provider>
  );
}

export * from './private';
