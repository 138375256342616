import { Helmet } from 'react-helmet-async';

interface Props {
  description?: string;
  title?: string;
}

function Meta(props: Props): JSX.Element {
  /* Define state / hook */
  const { description, title } = props;

  /* Custom Data Object for render or other usages */

  /* Render control function / logic */

  /* Data Change Listeners - useEffect/useMemo/useCallback */

  /* Styling */

  /* Render */
  return (
    <Helmet>
      <title>{title}</title>
      <meta content={title} name="title" />
      <meta content={description} name="description" />
    </Helmet>
  );
}

export default Meta;
