// Reduce bundle size
import type { Web3Provider } from '@ethersproject/providers';

// https://github.com/dethcrypto/TypeChain/issues/771
import { ACTION_REJECTED, INSUFFICIENT_FUNDS, LEGAL_TENDER } from '@common';
import { ethers } from 'ethers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCurrency } from 'src/hooks';
import { createContainer } from 'unstated-next';

import { api } from './api';
import { user } from './user';
import { web3 } from './web3';

const { formatEther, parseUnits, ZeroAddress } = ethers;

function useMarket() {
  const {
    chainId: connectedChainId,
    getAddress,
    getMarketAddress,
    provider,
    transactionExplorerBaseURL,
  } = web3.useContainer();
  const address = getAddress();
  const { getCurrencyAddressById } = useCurrency();
  const { userData } = user.useContainer();
  const {
    cancelOrder: cancelOrderAPI,
    createAuction: createAuctionAPI,
    createSale: createSaleAPI,
    getLoginStatus,
    takeHighestBid: takeHighestBidAPI,
  } = api.useContainer();

  const [isSigned, setIsSigned] = useState(false);
  const { _paramsChainId } = useParams();

  // Params is the highest piority
  const chainId =
    Number(_paramsChainId) ||
    connectedChainId ||
    Number(process.env.REACT_APP_DEFAULT_CHAINID);

  const { t } = useTranslation();

  const getErrorSign = (name: string) => {
    const hash = ethers.keccak256(ethers.toUtf8Bytes(`${name}()`));
    return ethers.dataSlice(hash, 0, 4);
  };

  const toastAction = (action: string, hash: string) => {
    toast.success(action, {
      onClick: () => {
        if (chainId)
          window.open(
            `${transactionExplorerBaseURL(chainId)}/${hash}`,
            '_blank'
          );
      },
    });
  };

  const toastError = (error: any) => {
    if (error?.code === 4001 || error?.code === ACTION_REJECTED) {
      toast.info(t('toastMessage.4001'));
      return;
    }
    if (error?.code === -32000 || error?.code === INSUFFICIENT_FUNDS) {
      toast.info(t('toastMessage.-32000'));
      return;
    }
    if (error?.code === -32602) {
      toast.info(t('toastMessage.-32602'));
      return;
    }
    if (error?.code === -32603) {
      toast.info(t('toastMessage.-32603'));
      return;
    }

    const errorSignature = error?.error?.data?.data;
    // For minting
    if (errorSignature) {
      switch (errorSignature) {
        case getErrorSign('InsufficientFunds'):
          toast.error(t('toastMessage.checkBalance'));
          return;
        case getErrorSign('InvalidMintAmountPerPeriod'):
          toast.error(t('toastMessage.mintAmount'));
          return;
        case getErrorSign('InvalidMintAmountPerTx'):
          toast.error(t('toastMessage.mintQuantity'));
          return;
        case getErrorSign('InvalidSignature'):
          toast.error(t('toastMessage.noMintApproval'));
          return;
        case getErrorSign('InvalidTimestamp'):
          toast.error(t('toastMessage.mintTimeNotCorrect'));
          return;
        case getErrorSign('UnexistentToken'):
          toast.error(t('toastMessage.tokenNotExist'));
          return;
        case getErrorSign('UnexpectedMintAmount'):
          toast.error(t('toastMessage.allMinted'));
          return;
        default:
          break;
      }
    }

    const errMsg = error?.error?.message;
    let toastMsg = '';
    if (errMsg?.match('transfer amount exceeds balance')) {
      toastMsg = t('toastMessage.checkBalance');
    } else if (errMsg?.match('insufficient balance')) {
      toastMsg = t('toastMessage.checkBalance');
    } else if (errMsg?.match('Auction is not yet over')) {
      toastMsg = t('toastMessage.AuctionPeriod');
    } else if (errMsg?.match('unable to send value')) {
      toastMsg = t('toastMessage.transferError');
    } else if (errMsg?.match('Cannot specify 0 address')) {
      toastMsg = t('toastMessage.walletError');
    } else if (errMsg?.match('Bid increase percentage too low')) {
      toastMsg = t('toastMessage.highterBidAndBalance');
    } else if (errMsg?.match('nft transfer failed')) {
      toastMsg = t('toastMessage.transferError');
    } else if (errMsg?.match("Seller doesn't own NFT")) {
      toastMsg = t('toastMessage.sellerNFT');
    } else if (errMsg?.match('Auction already started by owner')) {
      toastMsg = t('toastMessage.AuctionStarted');
    } else if (errMsg?.match('The order not exists')) {
      toastMsg = t('toastMessage.OrderExist');
    } else if (errMsg?.match('no credits to withdraw')) {
      toastMsg = t('toastMessage.noFailCredit');
    } else if (errMsg?.match('withdraw failed')) {
      toastMsg = t('toastMessage.withdrawFailCredits');
    } else if (errMsg?.match("Sender doesn't own NFT")) {
      toastMsg = t('toastMessage.NFTINWallet');
    } else if (errMsg?.match('Auction has ended')) {
      toastMsg = t('toastMessage.AuctionEnded');
    } else if (errMsg?.match('The auction has a valid bid made')) {
      toastMsg = t('toastMessage.cannotCancel');
    } else if (errMsg?.match('cannot payout 0 bid')) {
      toastMsg = t('toastMessage.noBid');
    } else if (errMsg?.match('Price cannot be 0')) {
      toastMsg = t('toastMessage.inputValidateFloorPrice');
    } else if (errMsg?.match('Owner cannot bid on own NFT')) {
      toastMsg = t('toastMessage.ownerBid');
    } else if (errMsg?.match('Bid to be in specified ERC20/Eth')) {
      toastMsg = t('toastMessage.bidCurrencyError');
    } else if (errMsg?.match('Not enough funds to bid on NFT')) {
      toastMsg = t('toastMessage.highterBidAndBalance');
    } else if (errMsg?.match('Only nft seller')) {
      toastMsg = t('toastMessage.yourNotSeller');
    } else if (errMsg?.match('Not NFT owner')) {
      toastMsg = t('toastMessage.settleAuctionRequired');
    } else if (errMsg?.match('Not NFT seller')) {
      toastMsg = t('toastMessage.cancelOrder');
    } else if (errMsg?.match('Auction End Timestamp is Invalid')) {
      toastMsg = t('toastMessage.futureTimeRequired');
    } else if (errMsg?.match('Pausable: paused')) {
      toastMsg = t('toastMessage.mintStopped');
    } else if (errMsg?.match('execution reverted')) {
      toastMsg = t('toastMessage.unexceptedError');
    } else {
      toastMsg = t('alertMessage.transcationFail');
    }

    toast.error(toastMsg);
  };

  const payment = async (): Promise<boolean> => {
    // FIXME: remove this function
    return getLoginStatus();
  };

  const getMarketContract = async () => {
    if (!address || !provider) return null;
    const { NFTIVMarketplace721Upgradeable__factory } = await import(
      '@nftiv/utils/dist/types/contracts/factories/packages/marketplace-721/contracts/NFTIVMarketplace721Upgradeable__factory'
    );
    return NFTIVMarketplace721Upgradeable__factory.connect(
      getMarketAddress(),
      provider?.getSigner()
    );
  };

  const getLaunchPadSign721Contract = async (contractAddress: string) => {
    if (!address || !provider) return null;
    const { NFTIVLaunchPadSign721__factory } = await import(
      '@nftiv/utils/dist/types/contracts/factories/@nftiv/launchpad-721/contracts/NFTIVLaunchPadSign721__factory'
    );
    return NFTIVLaunchPadSign721__factory.connect(
      contractAddress,
      provider?.getSigner()
    );
  };

  const getCollectionContract = async (contractAddress: string) => {
    if (!address || !provider) return null;
    const { ERC721Psi__factory } = await import(
      '@nftiv/utils/dist/types/contracts/factories/@nftiv/launchpad-721/contracts/libs/ERC721Psi__factory'
    );
    return ERC721Psi__factory.connect(contractAddress, provider?.getSigner());
  };

  const getERC20Contract = async (contractAddress: string) => {
    if (!address || !provider) return null;
    const { ERC20__factory } = await import(
      '@nftiv/utils/dist/types/contracts/factories/@openzeppelin/contracts/token/ERC20/ERC20__factory'
    );
    return ERC20__factory.connect(contractAddress, provider?.getSigner());
  };

  const getBalance = async (currencyId: number): Promise<string> => {
    if (!address || !provider) return '0';

    const currencyAddress = getCurrencyAddressById(currencyId);

    if (currencyAddress === ZeroAddress) {
      const result = await (provider as Web3Provider).getBalance(address);
      const balance = formatEther(result.toBigInt());
      return balance;
    }

    const ERC20Contract = await getERC20Contract(currencyAddress);
    if (!ERC20Contract) return '0';

    const erc20balance = await ERC20Contract?.balanceOf(address);
    const balance = formatEther(erc20balance.toBigInt());
    return balance;
  };

  const approveERC20 = async (
    currencyId: number,
    approveAddress = getMarketAddress(),
    minimumQuantity = parseUnits(String(1e18))
  ): Promise<boolean> => {
    setIsSigned(false);
    const ERC20Address = getCurrencyAddressById(currencyId);
    if (ERC20Address === ZeroAddress) return true;
    const approveContract = await getERC20Contract(ERC20Address);

    try {
      if (address) {
        const allowance = await approveContract?.allowance(
          address,
          approveAddress
        );
        if (allowance) {
          if (allowance.gt(minimumQuantity)) return true;

          const transaction = await approveContract?.approve(
            approveAddress,
            parseUnits(String(1e18))
          );
          setIsSigned(true);
          try {
            await transaction?.wait();
          } catch (error: any) {
            return false;
          }

          setIsSigned(false);
          return true;
        }
      }
    } catch (error: any) {
      // Show error message in the upper scope
      return false;
    }

    return false;
  };

  const approveNft = async (contractAddress: string): Promise<boolean> => {
    setIsSigned(false);
    const approveContract = await getCollectionContract(contractAddress);
    try {
      if (address) {
        const isApproved = await approveContract?.isApprovedForAll(
          address,
          getMarketAddress()
        );
        if (!isApproved) {
          const transaction = await approveContract?.setApprovalForAll(
            getMarketAddress(),
            true
          );
          setIsSigned(true);
          try {
            await transaction?.wait();
          } catch (error: any) {
            return false;
          }
        }
        setIsSigned(false);
        return true;
      }
    } catch (error: any) {
      // Show error message in the upper scope
      return false;
    }

    return false;
  };

  const transferNft = async (
    collectionAddress: string,
    treasuryAddress: string,
    mintedTokenId: number,
    encodedData: string
  ) => {
    setIsSigned(false);
    if (!address) {
      toast.error(t('alertMessage.connectMetaMask'));
      return false;
    }

    const approveContract = await getCollectionContract(collectionAddress);

    try {
      const transaction = await approveContract?.[
        'safeTransferFrom(address,address,uint256,bytes)'
      ](address, treasuryAddress, mintedTokenId, encodedData);

      if (transaction?.hash) {
        setIsSigned(true);
        try {
          await transaction?.wait();
        } catch (error: any) {
          toastError(error);
          return false;
        }
        toastAction(
          t('toastMessage.transferTreasurySuccessful'),
          transaction?.hash
        );
        return true;
      }
    } catch (error) {
      toastError(error); // For denied transaction
      return false;
    }
    return false;
  };

  /**
   * Fiat flow will not call this function, it will go throw the payment flow directly
   */
  const mint = async (
    launchpadAddress: string,
    price: string,
    quality: number,
    encodedData: string,
    sign: string,
    transferData: string,
    currencyId: number
  ): Promise<boolean> => {
    setIsSigned(false);
    const launchpadContract =
      await getLaunchPadSign721Contract(launchpadAddress);
    const mintPrice = parseUnits(price);
    const isERC20Token = () => {
      const erc20Address = getCurrencyAddressById(currencyId);
      return erc20Address !== ZeroAddress && erc20Address !== LEGAL_TENDER;
    };

    try {
      if (!address || !provider) {
        toast.error(t('alertMessage.connectMetaMask'));
        return false;
      }

      const totalMintPrice = mintPrice * BigInt(quality);
      if (totalMintPrice > parseUnits(await getBalance(currencyId))) {
        toast.error(t('toastMessage.checkBalance'));
        return false;
      }

      if (isERC20Token()) {
        const isApproveERC20 = await approveERC20(
          currencyId,
          launchpadAddress,
          totalMintPrice
        );

        if (!isApproveERC20) {
          toast.error(t('toastMessage.appriveERC20Required'));
          return false;
        }
      }

      const transaction = await launchpadContract?.mint(
        quality,
        encodedData,
        sign,
        transferData,
        {
          value: isERC20Token() ? undefined : totalMintPrice,
        }
      );
      if (transaction?.hash) {
        setIsSigned(true);
        try {
          await transaction?.wait();
        } catch (error: any) {
          toastError(error);
          return false;
        }
        toastAction(t('toastMessage.mintSuccessful'), transaction?.hash);

        return true;
      }
    } catch (error: any) {
      toastError(error); // For denied transaction
      return false;
    }

    return false;
  };

  /**
   * For fiat flow, will only call the API and return
   */
  const createSale = async (
    collectionAddress: string,
    tokenId: number,
    currencyId: number,
    paymentMethodId: string,
    sellingPrice: number,
    quantity: number
  ): Promise<boolean> => {
    setIsSigned(false);
    const sellerAddress = userData?.user?.wallet?.[0].wallet_address;
    if (!sellerAddress) return false;
    try {
      /**
       * For crypto flow, this API only for getting the identifier and pass to contract
       * For fiat flow, it will still return the nonce but it is useless, but we have to
       * call it to create a pending transaction order
       */
      const data = await createSaleAPI({
        chainId,
        collectionAddress,
        paymentMethodId,
        quantity,
        sellerAddress,
        sellingPrice,
        tokenId,
      });
      if (!data?.nonce) return false;

      const currencyAddress = getCurrencyAddressById(currencyId);
      if (currencyAddress === LEGAL_TENDER) {
        toast.success(t('toastMessage.createSellSuccessful'));
        return true;
      }

      /**
       * CRYPTO FLOW
       */
      if (!address) {
        toast.error(t('alertMessage.connectMetaMask'));
        return false;
      }

      const isApproveNFT = await approveNft(collectionAddress);
      if (!isApproveNFT) {
        toast.error(t('toastMessage.approveNFTRequired'));
        return false;
      }

      const marketContract = await getMarketContract();
      const bigSellingPrice = parseUnits(String(sellingPrice));
      try {
        const transaction = await marketContract?.createSale(
          collectionAddress,
          tokenId,
          currencyAddress,
          bigSellingPrice,
          data.nonce
        );
        if (transaction?.hash) {
          setIsSigned(true);
          try {
            await transaction?.wait();
          } catch (error: any) {
            toastError(error);
            return false;
          }
          toastAction(
            t('toastMessage.createSellSuccessful'),
            transaction?.hash
          );
          return true;
        }
      } catch (error) {
        toastError(error); // For denied transaction
        return false;
      }
    } catch (error: any) {
      // Catch API error and return so that the modal can handling UI logic
      return false;
    }

    return false;
  };

  /**
   * For fiat flow, will only call the API and return
   */
  const createAuction = async (
    collectionAddress: string,
    tokenId: number,
    currencyId: number,
    paymentMethodId: string,
    quantity: number,
    floorPrice: number,
    bidIncreasePercentage: number,
    endTimestamp: number
  ): Promise<boolean> => {
    setIsSigned(false);

    const sellerAddress = userData?.user?.wallet?.[0].wallet_address;
    if (!sellerAddress) return false;
    try {
      /**
       * For crypto flow, this API only for getting the identifier and pass to contract
       * For fiat flow, it will still return the nonce but it is useless, but we have to
       * call it to create a pending transaction order
       */
      const data = await createAuctionAPI({
        bidIncreasePercentage,
        chainId,
        collectionAddress,
        endTimestamp,
        floorPrice,
        paymentMethodId,
        quantity,
        sellerAddress,
        tokenId,
      });
      if (!data?.nonce) return false;

      const currencyAddress = getCurrencyAddressById(currencyId);
      if (currencyAddress === LEGAL_TENDER) {
        toast.success(t('toastMessage.createSellSuccessful'));
        return true;
      }

      /**
       * CRYPTO FLOW
       */
      if (!address) {
        toast.error(t('alertMessage.connectMetaMask'));
        return false;
      }

      const isApproveNFT = await approveNft(collectionAddress);
      if (!isApproveNFT) {
        toast.error(t('toastMessage.approveNFTRequired'));
        return false;
      }

      const marketContract = await getMarketContract();
      const bigFloorPrice = parseUnits(String(floorPrice));

      try {
        const transaction = await marketContract?.createAuction(
          collectionAddress,
          tokenId,
          currencyAddress,
          bigFloorPrice,
          endTimestamp,
          bidIncreasePercentage * 100,
          data.nonce
        );
        if (transaction?.hash) {
          setIsSigned(true);
          try {
            await transaction?.wait();
          } catch (error: any) {
            toastError(error);
            return false;
          }
          toastAction(
            t('toastMessage.createAuctionSuccessful'),
            transaction?.hash
          );
          return true;
        }
      } catch (error) {
        toastError(error); // For denied transaction
        return false;
      }
    } catch (error: any) {
      // Catch API error and return so that the modal can handling UI logic
      return false;
    }

    return false;
  };

  /**
   * Fiat flow will not call this function, it will go throw the payment flow directly
   */
  const makeBuy = async (
    collectionAddress: string,
    tokenId: number,
    currencyId: number,
    price: number
  ): Promise<boolean> => {
    setIsSigned(false);
    if (!address) {
      toast.error(t('alertMessage.connectMetaMask'));
      return false;
    }

    const isApproveERC20 = await approveERC20(currencyId);
    if (!isApproveERC20) {
      toast.error(t('toastMessage.appriveERC20Required'));
      return false;
    }

    const marketContract = await getMarketContract();
    const bigPrice = parseUnits(String(price));
    const currencyAddress = getCurrencyAddressById(currencyId);
    try {
      const transaction = await marketContract?.makeBuy(
        collectionAddress,
        tokenId,
        currencyAddress,
        currencyAddress === ZeroAddress ? 0 : bigPrice,
        { value: currencyAddress === ZeroAddress ? bigPrice : 0 }
      );
      if (transaction?.hash) {
        setIsSigned(true);
        try {
          await transaction?.wait();
        } catch (error: any) {
          toastError(error);
          return false;
        }
        toastAction(t('toastMessage.buySuccessful'), transaction?.hash);
        return true;
      }
    } catch (error) {
      toastError(error); // For denied transaction
      return false;
    }
    return false;
  };

  /**
   * Fiat flow will not call this function, it will go throw the payment flow directly
   */
  const makeBid = async (
    collectionAddress: string,
    tokenId: number,
    currencyId: number,
    bidPrice: number
  ): Promise<boolean> => {
    setIsSigned(false);
    if (!address) {
      toast.error(t('alertMessage.connectMetaMask'));
      return false;
    }

    const isApproveERC20 = await approveERC20(currencyId);
    if (!isApproveERC20) {
      toast.error(t('toastMessage.appriveERC20Required'));
      return false;
    }

    const marketContract = await getMarketContract();
    const bigBidPrice = parseUnits(String(bidPrice));
    const currencyAddress = getCurrencyAddressById(currencyId);
    try {
      const transaction = await marketContract?.makeBid(
        collectionAddress,
        tokenId,
        currencyAddress,
        currencyAddress === ZeroAddress ? 0 : bigBidPrice,
        { value: currencyAddress === ZeroAddress ? bigBidPrice : 0 }
      );
      if (transaction?.hash) {
        setIsSigned(true);
        try {
          await transaction?.wait();
        } catch (error: any) {
          toastError(error);
          return false;
        }
        toastAction(t('toastMessage.bidSuccessful'), transaction?.hash);
        return true;
      }
    } catch (error) {
      toastError(error); // For denied transaction
      return false;
    }
    return false;
  };

  /**
   * Fiat flow will not call this function since fiat will auto settle by system
   */
  const settleAuction = async (
    collectionAddress: string,
    tokenId: number
  ): Promise<boolean> => {
    setIsSigned(false);
    const marketContract = await getMarketContract();

    try {
      const transaction = await marketContract?.settleAuction(
        collectionAddress,
        tokenId
      );
      if (transaction?.hash) {
        setIsSigned(true);
        try {
          await transaction?.wait();
        } catch (error: any) {
          toastError(error);
          return false;
        }
        toastAction(
          t('toastMessage.settleAuctionSuccesful'),
          transaction?.hash
        );

        return true;
      }
    } catch (error: any) {
      toastError(error); // For denied transaction
      return false;
    }

    return false;
  };

  /**
   * Fiat flow will call API, crypto flow will call contract
   */
  const takeHighestBid = async (
    collectionAddress: string,
    tokenId: number,
    currencyId: number,
    orderId: number
  ): Promise<boolean> => {
    setIsSigned(false);

    const currencyAddress = getCurrencyAddressById(currencyId);
    try {
      if (currencyAddress === LEGAL_TENDER) {
        await takeHighestBidAPI({
          chainId,
          collectionAddress,
          orderId,
          tokenId,
        });
        toast.success(t('toastMessage.takeHighestBidSuccesful'));
        return true;
      }

      /**
       * CRYPTO FLOW
       */
      if (!address) {
        toast.error(t('alertMessage.connectMetaMask'));
        return false;
      }

      const marketContract = await getMarketContract();
      const transaction = await marketContract?.takeHighestBid(
        collectionAddress,
        tokenId
      );
      if (transaction?.hash) {
        setIsSigned(true);
        try {
          await transaction?.wait();
        } catch (error: any) {
          toastError(error);
          return false;
        }
        toastAction(
          t('toastMessage.takeHighestBidSuccesful'),
          transaction?.hash
        );

        return true;
      }
    } catch (error: any) {
      toastError(error); // For denied transaction
      return false;
    }

    return false;
  };

  /**
   * Fiat flow will call API, crypto flow will call contract
   */
  const cancelAuction = async (
    collectionAddress: string,
    tokenId: number,
    currencyId: number,
    orderId: number
  ): Promise<boolean> => {
    setIsSigned(false);
    const currencyAddress = getCurrencyAddressById(currencyId);
    try {
      if (currencyAddress === LEGAL_TENDER) {
        await cancelOrderAPI({
          chainId,
          collectionAddress,
          orderId,
          tokenId,
        });
        toast.success(t('toastMessage.cancelSuccessful'));
        return true;
      }

      /**
       * CRYPTO FLOW
       */
      if (!address) {
        toast.error(t('alertMessage.connectMetaMask'));
        return false;
      }

      const marketContract = await getMarketContract();
      const transaction = await marketContract?.withdrawAuction(
        collectionAddress,
        tokenId
      );
      if (transaction?.hash) {
        setIsSigned(true);
        try {
          await transaction?.wait();
        } catch (error: any) {
          toastError(error);
          return false;
        }
        toastAction(t('toastMessage.cancelSuccessful'), transaction?.hash);

        return true;
      }
    } catch (error: any) {
      toastError(error); // For denied transaction
      return false;
    }

    return false;
  };

  return {
    cancelAuction,
    createAuction,
    createSale,
    getBalance,
    isSigned,
    makeBid,
    makeBuy,
    mint,
    payment,
    settleAuction,
    takeHighestBid,
    transferNft,
  };
}

export const market = createContainer(useMarket);
