import { api } from '@state/api';
import { web3 } from '@state/web3';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

const useSystemConfig = () => {
  const { getConfig } = api.useContainer();
  const { _paramsChainId } = useParams();
  const { chainId } = web3.useContainer();
  const queryChainId = Number(_paramsChainId) || chainId;
  const { data } = useQuery(
    [getConfig.name, queryChainId],
    () => getConfig(queryChainId),
    {
      refetchOnMount: false,
    }
  );

  return { config: data };
};

export { useSystemConfig };
